import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
	DefOuter,
	SubTitle,
	Item,
	ItemImg,
	ItemTitle,
	ItemAttention,
} from '../style';
import VoiceImg1 from '../../img/chef_voice_01.jpg';
import VoiceImg1_2x from '../../img/chef_voice_01@2x.jpg';
import VoiceImg2 from '../../img/chef_voice_02.jpg';
import VoiceImg2_2x from '../../img/chef_voice_02@2x.jpg';
import VoiceImg3 from '../../img/chef_voice_03.jpg';
import VoiceImg3_2x from '../../img/chef_voice_03@2x.jpg';
import VoiceImg4 from '../../img/chef_voice_04.jpg';
import VoiceImg4_2x from '../../img/chef_voice_04@2x.jpg';

const Outer = styled.section`
	${DefOuter}
	width: 90%;
`;
const SmallTxt = styled.span`
	font-size: 1.6rem;
	@media ${v.media_md} {
		font-size: 1.8rem;
	}
`;

function ChefVoice() {
	const settings = {
		autoplay: true,
		autoplaySpeed: 3000,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: true,
		arrows: false,
		responsive: [
			{
				breakpoint: 1120,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 709,
				settings: {
					adaptiveHeight: true,
					slidesToShow: 1,
				},
			},
		],
	};
	return (
		<Outer>
			<SubTitle>
				<span>お客様の声</span>
				<span>voice</span>
			</SubTitle>
			<div>
				<Slider {...settings}>
					<Item>
						<ItemImg
							src={VoiceImg1}
							srcSet={`${VoiceImg1} 1x, ${VoiceImg1_2x} 2x`}
							alt="Aさんの出張シェフの様子"
							width="300"
							height="300"
						/>
						<ItemTitle>
							Aさん<SmallTxt>(大人4名/子供2名)</SmallTxt>
						</ItemTitle>
						<ItemAttention>
							出掛けられない日々が続いていたのと、日頃のお礼を兼ねて出張シェフをお願いしました。小さな子ども達も自由にできる良さと、キレイなテーブルセッティング等で自分家じゃないような特別感。どのお料理もおいしかったです！濱西夫妻の人柄もあたたかで、気兼ねなくお酒も入って楽しめました♪
						</ItemAttention>
					</Item>
					<Item>
						<ItemImg
							src={VoiceImg2}
							srcSet={`${VoiceImg2} 1x, ${VoiceImg2_2x} 2x`}
							alt="Bさんの出張シェフの様子"
							width="300"
							height="300"
						/>
						<ItemTitle>
							Bさん<SmallTxt>(大人5名)</SmallTxt>
						</ItemTitle>
						<ItemAttention>
							・お野菜から阿波美豚、四国三郎牛に川海老等々、沢山の地産食材をお選び下さり、濱西さんのこだわりを感じました。ゆっくり美味しい秋を堪能させていただき、皆で幸せな時間を過ごすことができました。
							<br />
							・とても豪華な、お祝いにふさわしいメニューをご準備下さりありがとうございました。どのお料理も大変美味しくいただきました。テーブルコーディネートやお花、電飾も素敵で、幻想的な良い雰囲気でした。
							<br />
							(リピーターのお客様)
						</ItemAttention>
					</Item>
					<Item>
						<ItemImg
							src={VoiceImg3}
							srcSet={`${VoiceImg3} 1x, ${VoiceImg3_2x} 2x`}
							alt="Cさんの出張シェフの様子"
							width="300"
							height="300"
						/>
						<ItemTitle>
							Cさん<SmallTxt>(大人6名)</SmallTxt>
						</ItemTitle>
						<ItemAttention>
							今回家族で娘の入籍祝いをするために出張シェフを利用させていただきました。娘夫婦の自宅にシェフに来ていただき、珍しい食材や地元の食材をふんだんに取り入れたシェフ自慢の料理を、楽しい説明付きで味わいました。事前の打ち合わせで、いろんな要望も快く聞いていただき、また食事会の最後には娘夫婦にプレゼントまでいただくサプライズ演出で、まさに思い出に残る特別な食事会になりました。80歳を超える祖父母も美味しかったと大絶賛。また何かの記念日にお願いしたいねと話しておりました。濱西シェフご夫妻、本当にありがとうございました。
						</ItemAttention>
					</Item>
					<Item>
						<ItemImg
							src={VoiceImg4}
							srcSet={`${VoiceImg4} 1x, ${VoiceImg4_2x} 2x`}
							alt="Dさんの出張シェフの様子"
							width="300"
							height="300"
						/>
						<ItemTitle>
							Dさん<SmallTxt>(大人4名/子供2名)</SmallTxt>
						</ItemTitle>
						<ItemAttention>
							昨年の春に生まれた長男のお宮参り祝いに、とっても素敵なお料理の華を添えてくれた濱西さん夫妻。コロナ禍でなくとも、子連れではなかなか行くことができない本格レストランの味には、終始ほっぺがとろけっぱなし。目の前で次々と出来上がるお料理たちに家族みんな５感全てを使って楽しませてもらった感覚でした。最後は片付けやお掃除まで、、！夫婦の優しい優しい人柄も相まって、お腹も心もすっかり満たされた時間となりました。
						</ItemAttention>
					</Item>
				</Slider>
			</div>
		</Outer>
	);
}
export default ChefVoice;
