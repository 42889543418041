import { HashLink } from 'react-router-hash-link';
import styled, { css } from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;

// 以下共通cssの設定

// --------------------
// wrap要素
// --------------------
// デフォルトのアウター
export const DefOuter = css`
	width: 100%;
	max-width: 1366px;
	margin: 0 auto ${v.sec_mb};
	@media ${v.media_md} {
		padding: 0 20px;
		margin-bottom: ${v.sec_mb_lg};
	}
`;
export const OuterPadding = css`
	padding: 0 10px;
	@media ${v.media_md} {
		padding: 0;
	}
`;
// --------------------
// 汎用スタイル
// --------------------
// 文章内改行
export const Break = styled.span`
	display: inline-block !important;
`;
// PCのみ改行
export const BrPC = styled.br`
	display: none;
	@media ${v.media_md} {
		display: block;
	}
`;
// 注意文スタイル
export const Attention = styled.p`
	font-size: 1.4rem;
	@media ${v.media_md} {
		font-size: 1.6rem;
	}
`;
// 黄色枠の注意書きBox
export const YellowBox = styled.div`
	display: inline-block;
	padding: 10px;
	margin-top: 20px;
	background-color: ${v.color_sub};
	border: solid 3px ${v.color_acc};
	font-size: 1.4rem;
	text-align: left;
	@media ${v.media_md} {
		padding: 20px;
		margin: 30px 10px 0;
		font-size: 1.6rem;
	}
`;
// --------------------
// タイトル
// --------------------
// タイトル共通スタイル
export const TitleBase = css`
	font-weight: ${v.font_weight_bold};
	line-height: 1;
	@media ${v.media_md} {
		letter-spacing: ${v.ls_title_lg};
	}
`;
// 黄色背景のタイトル
export const YellowTitle = styled.span`
	${TitleBase}
	display: inline-block;
	padding: 10px;
	margin-bottom: 10px;
	background-color: ${v.color_acc};
	font-size: 2.2rem;
	@media ${v.media_md} {
		padding: 10px;
		font-size: 3.2rem;
		&:last-of-type {
			margin-bottom: 20px;
		}
	}
`;
// タイトル
export const Title = styled.h1`
	${TitleBase}
	@media ${v.media_md} {
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	span {
		display: block;
		/* 英語 */
		&:first-child {
			font-size: 3rem;
			text-transform: uppercase;
			letter-spacing: ${v.ls_title_sm};
			@media ${v.media_md} {
				margin-right: 40px;
				letter-spacing: ${v.ls_title_lg};
				font-size: 5rem;
			}
		}
		/* 日本語 */
		&:last-child {
			margin-top: 10px;
			font-size: 1.6rem;
			@media ${v.media_md} {
				font-size: 2.2rem;
			}
		}
	}
`;
// サブタイトル
export const SubTitle = styled.h2`
	${TitleBase}
	margin-bottom: 30px;
	text-align: center;
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
	span {
		display: block;
		/* 日本語 */
		&:first-child {
			font-size: 1.4rem;
			@media ${v.media_md} {
				font-size: 2rem;
			}
		}
		/* 英語 */
		&:last-child {
			margin-top: 10px;
			font-size: 2.8rem;
			text-transform: uppercase;
			letter-spacing: ${v.ls_title_sm};
			@media ${v.media_md} {
				font-size: 4rem;
			}
		}
	}
`;
// サブより小さいタイトル(box内など)
export const SecTitle = styled.h2`
	${TitleBase}
	margin-bottom: 10px;
	letter-spacing: ${v.ls_title_sm};
	text-transform: uppercase;
	@media ${v.media_md} {
		margin-bottom: 20px;
	}
	/* 和文 */
	span:first-child {
		display: block;
		font-size: 1.4rem;
		margin-bottom: 5px;
		@media ${v.media_md} {
			font-size: 1.6rem;
		}
	}
	/* 英字 */
	span:last-child {
		display: block;
		font-size: 2.6rem;
		@media ${v.media_md} {
			font-size: 3rem;
		}
	}
`;
// Takeoutで使うタイトル
export const TakeoutTitle = styled.div`
	margin-bottom: 20px;
	text-align: center;
	@media ${v.media_md} {
		margin-bottom: 30px;
	}
	h3 {
		${TitleBase}
		margin-bottom: 5px;
		font-size: 2rem;
		@media ${v.media_md} {
			font-size: 2.6rem;
			letter-spacing: ${v.ls_title_sm};
		}
	}
`;
// --------------------
// ボタン
// --------------------
// TopのREAD MOREボタン
export const BtnMore = styled(HashLink)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 160px;
	height: 30px;
	margin-left: auto;
	background-color: ${v.color_acc};
	border: 2px solid ${v.color_txt};
	border-radius: 9999px;
	font-size: 1.4rem;
	text-transform: uppercase;
	letter-spacing: ${v.ls_title_sm};
	font-weight: ${v.font_weight_bold};
	color: ${v.color_txt};
	text-decoration: none;
	@media ${v.media_md} {
		flex-shrink: 0;
		margin-left: 0;
		font-size: 1.6rem;
	}
	@media all and (any-hover: hover) {
		&:hover {
			color: ${v.color_accent};
			border: 2px solid ${v.color_acc};
		}
	}
`;

// btnのwrapper
export const Btns = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;
	@media ${v.media_sm} {
		flex-direction: row;
	}
`;
// SNSシェアボタン
export const BtnSNS = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 240px;
	height: 45px;
	/* padding: 0 50px; */
	background-color: ${v.color_main};
	border: 2px solid ${v.color_main};
	border-radius: 9999px;
	font-size: 1.6rem;
	text-transform: capitalize;
	letter-spacing: ${v.ls_title_sm};
	font-weight: ${v.font_weight_bold};
	color: ${v.color_sub};
	text-decoration: none;
	&:first-child {
		margin-bottom: 20px;
	}
	@media ${v.media_sm} {
		&:first-child {
			margin-bottom: 0px;
			margin-right: 20px;
		}
	}
	@media ${v.media_md} {
		height: 50px;
		font-size: 1.6rem;
	}
	@media all and (any-hover: hover) {
		&:hover {
			border-color: ${v.color_main};
			background-color: ${v.color_sub};
			color: ${v.color_main};
		}
	}
	svg {
		margin-right: 10px;
	}
`;

// --------------------
// 3つ並んだ写真のリスト
// --------------------
export const ItemList = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${DefOuter}
	${OuterPadding}
	@media ${v.media_sm} {
		flex-direction: row;
		justify-content: space-around;
		align-items: flex-start;
	}
	@media ${v.media_md} {
		width: 90%;
	}
`;
export const Item = styled.li`
	display: block !important;
	max-width: 300px;
	margin: 0 auto 30px;
	@media ${v.media_sm} {
		width: 30%;
		margin-bottom: 0;
	}
`;
export const ItemImg = styled.img`
	width: 100%;
	height: auto;
	margin-bottom: 15px;
`;
export const ItemTitle = styled.h2`
	margin-bottom: 10px;
	font-size: 1.8rem;
	font-weight: ${v.font_weight_bold};
	letter-spacing: ${v.title_ls};
	line-height: 1;
	text-align: center;
	@media ${v.media_md} {
		font-size: 2.2rem;
	}
`;
export const ItemAttention = styled.p`
	font-size: 1.4rem;
	@media ${v.media_md} {
		font-size: 1.6rem;
	}
`;

// --------------------
// takeout、仕出し料理のスタイル
// --------------------
export const TakeoutContents = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	padding: 0 10px;
	text-align: center;
	@media ${v.media_lg} {
		flex-direction: row;
		text-align: left;
	}
`;
export const TakeoutImg = styled.img`
	width: 100%;
	max-width: 380px;
	height: auto;
	margin-bottom: 20px;
	@media ${v.media_lg} {
		margin-bottom: 0;
		margin-right: 50px;
	}
`;
export const TakeoutName = styled.p`
	margin-bottom: 10px;
	font-size: 1.8rem;
	font-weight: ${v.font_weight_bold};
	line-height: 1;
	letter-spacing: ${v.ls_title_sm};
	@media ${v.media_md} {
		font-size: 2.2rem;
	}
`;
export const SubTakeoutName = styled(TakeoutName)`
	font-size: 1.6rem;
	font-weight: ${v.font_weight};
	@media ${v.media_md} {
		font-size: 1.8rem;
	}
`;
