import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { IconFb, IconInsta } from '../icon';
import { Break } from '../style';
import { scrollWithOffset } from '../scrollWithOffset';
import styled from 'styled-components';
import stylevs from '../../style-variables.json';
const v = stylevs.var;
import Logo from '../../img/logo.svg';

const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	width: 100%;
	height: 50px;
	padding: 0 15px;
	/* background-color: ${v.color_acc}; */
	text-transform: uppercase;
	font-weight: ${v.font_weight_bold};
	line-height: 1;
	letter-spacing: ${v.ls_title_sm};
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background: rgba(255, 252, 242, 0.2);
		backdrop-filter: blur(10px);
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
	@media ${v.media_lg} {
		height: 90px;
		align-items: center;
	}
`;
const GnavBtn = styled.button`
	position: relative;
	/* top: 0; */
	/* right: 20px; */
	display: inline-block;
	appearance: none;
	border: none;
	cursor: pointer;
	z-index: 9999;
	width: 45px;
	height: 45px;
	border-radius: 5px;
	/* border: 1px solid ${v.color_main}; */
	outline: none;
	background-color: ${v.color_sub};
	background-color: transparent;
	:hover,
	:focus {
		span::before {
			transform: rotate(10deg);
		}
		span::after {
			transform: rotate(-10deg);
		}
	}
	/* メニューOPEN時の<Line>の動き */
	&[aria-expanded='true'] span {
		background-color: transparent;
	}
	&[aria-expanded='true'] span::before,
	&[aria-expanded='true'] span::after {
		top: 0;
		background-color: ${v.color_main};
	}
	&[aria-expanded='true'] span::before {
		transform: rotate(45deg);
	}
	&[aria-expanded='true'] span::after {
		transform: rotate(-45deg);
	}
	@media ${v.media_lg} {
		display: none;
	}
`;
// ハンバーガーボタンの線
const Line = styled.span`
	position: absolute;
	/* 高さの相殺 */
	top: calc(50% - 2px);
	left: 50%;
	transform: translate(-50%);
	width: 22px;
	height: 2px;
	border-radius: 1px;
	background-color: ${v.color_main};
	transition: all 0.3s;
	::before,
	::after {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: inherit;
		background-color: inherit;
		transition: inherit;
	}
	::before {
		top: -8px;
	}
	::after {
		top: 8px;
	}
`;
const Nav = styled.nav`
	display: none;
	flex-direction: column;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 1366px;
	padding: 50px 20px;
	margin: 0 auto;
	background-color: ${v.color_sub};
	@media ${v.media_lg} {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		padding: 5px 10px;
		background-color: transparent;
	}
	&[aria-expanded='true'] {
		display: flex;
	}
`;
const LogoImg = styled.img`
	width: 120px;
	height: auto;
	margin-bottom: 30px;
	@media ${v.media_lg} {
		width: 80px;
		margin-bottom: 0;
	}
`;
const List = styled.ul`
	display: flex;
	&:first-of-type {
		flex-direction: column;
		align-items: center;
	}
	&:last-of-type {
		justify-content: space-between;
		width: 80px;
		margin: 20px 0;
	}
	@media ${v.media_lg} {
		&:first-of-type {
			flex-direction: row;
		}
		&:last-of-type {
			margin: 0;
		}
	}
	li {
		@media ${v.media_lg} {
			&:not(:last-child) {
				margin-right: 35px;
			}
		}
	}
`;
// Navリンクの共通スタイル
const StyledLink = styled(HashLink)`
	display: inline-block;
	position: relative;
	color: ${v.color_main};
	font-size: 2rem;
	text-align: center;
	margin-bottom: 10px;
	text-decoration: none;
	@media ${v.media_lg} {
		margin-bottom: 0;
		text-align: left;
		font-size: 2rem;
	}
	@media all and (any-hover: hover) {
		&:hover {
			&:before {
				content: '';
				display: block;
				position: absolute;
				bottom: -5px;
				width: 100%;
				height: 2px;
				background-color: ${v.color_main};
			}
		}
	}
`;
const StyledExternal = styled(StyledLink)`
	margin-bottom: 0;
`;
const StyledLinkLogo = styled(StyledLink)`
	margin-bottom: 0;
	@media ${v.media_lg} {
		display: none;
	}
`;
const ReserveButton = styled(HashLink)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 50px;
	border-radius: 999px;
	background-color: ${v.color_main};
	border: 3px solid ${v.color_main};
	color: ${v.color_sub};
	text-decoration: none;
	@media all and (any-hover: hover) {
		&:hover {
			background-color: ${v.color_sub};
			color: ${v.color_main};
		}
	}
`;
// 「メニューを開閉する」テキストの非表示、スクリーンリーダー読み込ませるため(display: noneは読み込まれない)
const VisuallyHidden = styled.span`
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
`;

const Gnav = () => {
	const [isOpen, setOpen] = useState(false);
	const toggle = () => {
		setOpen(!isOpen);
	};
	// navmenuが開いている時にメニューリンクのどれかをクリックした時メニューを閉じる
	const closeNav = () => {
		if (isOpen === true) {
			setOpen(false);
		}
	};

	return (
		<Header>
			<StyledLinkLogo
				onClick={closeNav}
				smooth
				to="/#"
				scroll={(el) => scrollWithOffset(el)}
			>
				TOGODELI KITCHEN
			</StyledLinkLogo>
			<GnavBtn
				type="button"
				id="GnavBtn"
				aria-controls="Gnav"
				aria-expanded={isOpen}
				onClick={toggle}
			>
				<Line>
					<VisuallyHidden>メニューを開閉する</VisuallyHidden>
				</Line>
			</GnavBtn>
			<Nav aria-controls="Gnav" aria-expanded={isOpen}>
				<HashLink
					onClick={closeNav}
					smooth
					to="/#"
					scroll={(el) => scrollWithOffset(el)}
				>
					<LogoImg src={Logo} alt="トゴデリキッチン" width="80" height="80" />
				</HashLink>
				<List>
					<li>
						<StyledLink
							smooth
							to="/chef#"
							onClick={closeNav}
							scroll={(el) => scrollWithOffset(el)}
						>
							private chef
						</StyledLink>
					</li>
					<li>
						<StyledLink
							smooth
							to="/takeout#"
							onClick={closeNav}
							scroll={(el) => scrollWithOffset(el)}
						>
							takeout & <Break>catered food</Break>
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="#news"
							scroll={(el) => scrollWithOffset(el)}
						>
							news
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/access#"
							scroll={(el) => scrollWithOffset(el)}
						>
							access
						</StyledLink>
					</li>
				</List>
				<List>
					<li>
						<StyledExternal
							as="a"
							onClick={closeNav}
							target="_blank"
							rel="noreferrer"
							href="https://www.facebook.com/TOGO.DELI.KITCHEN/"
						>
							<IconFb />
						</StyledExternal>
					</li>
					<li>
						<StyledExternal
							as="a"
							onClick={closeNav}
							target="_blank"
							rel="noreferrer"
							href="https://www.instagram.com/togo_deli_kitchen/"
						>
							<IconInsta />
						</StyledExternal>
					</li>
				</List>
				<ReserveButton
					onClick={closeNav}
					smooth
					to="#reserve"
					scroll={(el) => scrollWithOffset(el)}
				>
					reserve
				</ReserveButton>
			</Nav>
		</Header>
	);
};
export default Gnav;
