import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import AccessFirstview from '../components/access/access-firstview';
import AccessAccess from '../components/access/access-access';

const Access = () => {
	smoothscroll.polyfill();
	return (
		<main>
			<AccessFirstview />
			<AccessAccess />
		</main>
	);
};
export default Access;
