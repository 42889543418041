import React from 'react';
import styled from 'styled-components';
import { DefOuter, OuterPadding } from '../style';

const Outer = styled.div`
	${OuterPadding}
	${DefOuter}
	max-width: 800px;
`;
const Youtube = styled.div`
	position: relative;
	width: 100%;
	padding-top: 56.25%;
	margin: 0 auto;
	iframe {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
`;

const ChefMovie = () => {
	return (
		<Outer>
			<Youtube>
				<iframe
					width="560"
					height="315"
					src="https://www.youtube.com/embed/yHiZwJ5ZMZs"
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen=""
				></iframe>
			</Youtube>
		</Outer>
	);
};
export default ChefMovie;
