import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import HomeFirstview from '../components/home/home-firstview';
import HomeConcept from '../components/home/home-concept';
import HomePoint from '../components/home/home-point';
import HomeChef from '../components/home/home-chef';
import HomeTakeout from '../components/home/home-takeout';

const Home = () => {
	smoothscroll.polyfill();
	return (
		<main>
			<HomeFirstview />
			<HomeConcept />
			<HomePoint />
			<HomeChef />
			<HomeTakeout />
		</main>
	);
};
export default Home;
