import React from 'react';
import styled from 'styled-components';
import { DefOuter, SubTitle, OuterPadding } from '../style';

import styleVariables from '../../style-variables.json';
const v = styleVariables.var;

import PriceImg1 from '../../img/chef_price_01.png';
import PriceImg1_2x from '../../img/chef_price_01@2x.png';
import PriceImg1_SP from '../../img/chef_price_01-SP.png';
import PriceImg1_SP_2x from '../../img/chef_price_01-SP@2x.png';

const Outer = styled.section`
	text-align: center;
	${OuterPadding}
	${DefOuter}
`;

const Img = styled.img`
	width: 100%;
	height: auto;
	max-width: 800px;
`;

const ChefPrice = () => {
	return (
		<Outer>
			<SubTitle>
				<span>料金</span>
				<span>price</span>
			</SubTitle>
			<picture>
				<source
					srcSet={`${PriceImg1} 1x, ${PriceImg1_2x} 2x`}
					media={`${v.media_sm}`}
				/>
				<Img
					src={PriceImg1_SP}
					srcSet={`${PriceImg1_SP} 1x, ${PriceImg1_SP_2x} 2x`}
					alt="料金説明"
					width="500"
					height="760"
				/>
			</picture>
		</Outer>
	);
};
export default ChefPrice;
