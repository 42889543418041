import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, SubTitle, OuterPadding, TitleBase } from '../style';

const Outer = styled.section`
	${DefOuter}
	${OuterPadding}
`;
const Wrapper = styled.dl`
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	@media ${v.media_md} {
		padding: 0 10px;
	}
	/* 共通設定 */
	dt,
	dd {
		padding: 10px 15px;
		border: solid 3px ${v.color_main};
		@media ${v.media_md} {
			padding: 15px 20px;
		}
	}
`;
const DTerm = styled.dt`
	${TitleBase}
	background-color: ${v.color_main};
	color: ${v.color_sub};
	line-height: ${v.lh_txt};
`;
const DDesc = styled.dd`
	background-color: ${v.color_sub};
	&:not(:last-child) {
		margin-bottom: 30px;
	}
`;

const ChefQA = () => {
	return (
		<Outer>
			<SubTitle>
				<span>よくある質問</span>
				<span>q&a</span>
			</SubTitle>
			<Wrapper>
				<DTerm>準備するものはありますか？</DTerm>
				<DDesc>
					お飲み物のみご準備をお願いしております。お皿や食材、お料理に関するものはこちらでご準備いたします。
				</DDesc>
				<DTerm>キッチンが狭いのですが、大丈夫ですか？</DTerm>
				<DDesc>コンロ2つとシンク1つがあれば大丈夫です。</DDesc>
				<DTerm>IHでも大丈夫ですか？</DTerm>
				<DDesc>IH、ガス火ともに対応可能です。</DDesc>
				<DTerm>子供の料理と大人の料理を分けて欲しいです。</DTerm>
				<DDesc>対応可能です。詳しくはお打ち合わせ時にご相談ください。</DDesc>
				<DTerm>アレルギーがありますが大丈夫ですか？</DTerm>
				<DDesc>もちろん大丈夫です。お打ち合わせ時にお伝えくださいませ。</DDesc>
				<DTerm>ディナーじゃないとダメですか？</DTerm>
				<DDesc>
					ランチも対応可能です。詳しくはお打ち合わせ時にご相談ください。
				</DDesc>
				<DTerm>リクエストする料理は作れますか？</DTerm>
				<DDesc>できる限りは対応します。</DDesc>
				<DTerm>今までどんな時に出張しましたか？</DTerm>
				<DDesc>
					記念日のお祝いや、少人数での忘年会開催などお客様のご都合に合わせて出張いたします。
				</DDesc>
			</Wrapper>
		</Outer>
	);
};
export default ChefQA;
