import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import ChefFirstview from '../components/chef/chef-firstview';
import ChefAbout from '../components/chef/chef-about';
import ChefGallery from '../components/chef/chef-gallery';
import ChefPrice from '../components/chef/chef-price';
import ChefMovie from '../components/chef/chef-movie';
import ChefFlow from '../components/chef/chef-flow';
import ChefProfile from '../components/chef/chef-profile';
import ChefVoice from '../components/chef/chef-voice';
import ChefQA from '../components/chef/chef-qa';

const Chef = () => {
	smoothscroll.polyfill();
	return (
		<main>
			<ChefFirstview />
			<ChefAbout />
			<ChefGallery />
			<ChefPrice />
			<ChefMovie />
			<ChefFlow />
			<ChefProfile />
			<ChefVoice />
			<ChefQA />
		</main>
	);
};
export default Chef;
