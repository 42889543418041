import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, Break, Title } from '../style';
import ChefImg1 from '../../img/chef_firstview_01.jpg';
import ChefImg1_2x from '../../img/chef_firstview_01@2x.jpg';
import ChefImg1_SP from '../../img/chef_firstview_01-SP.jpg';
import ChefImg1_SP_2x from '../../img/chef_firstview_01-SP@2x.jpg';

const Outer = styled.div`
	${DefOuter}
	margin-top: calc(50px + 30px);
	@media ${v.media_md} {
		margin-top: calc(90px + 60px);
		margin-bottom: 150px;
	}
`;
const StyledTitle = styled(Title)`
	padding: 0 10px;
	margin-bottom: 40px;
	@media ${v.media_md} {
		display: block;
		margin-bottom: 80px;
		/* 英語 */
		span:first-child {
			margin-right: 0;
		}
	}
`;
const Lead = styled.p`
	padding: 0 10px;
	margin-bottom: 40px;
	font-size: 1.8rem;
	font-weight: ${v.font_weight_bold};
	letter-spacing: ${v.ls_title_sm};
	line-height: 1.5;
	@media ${v.media_md} {
		margin-bottom: 80px;
		font-size: 3.2rem;
	}
`;
const Img = styled.img`
	width: 100%;
	height: auto;
	@media ${v.media_md} {
	}
`;
const ChefFirstview = () => {
	return (
		<Outer>
			<StyledTitle>
				<span>private chef</span>
				<span>出張シェフ</span>
			</StyledTitle>
			<Lead>
				特別な日に大切な人と過ごす時間を
				<Break>一緒に創造したい！</Break>
				<Break>食卓に笑顔と感動をお届けします。</Break>
			</Lead>
			<picture>
				<source
					srcSet={`${ChefImg1} 1x, ${ChefImg1_2x} 2x`}
					media={`${v.media_sm}`}
				/>
				<Img
					src={ChefImg1_SP}
					srcSet={`${ChefImg1_SP}1x, ${ChefImg1_SP_2x} 2x`}
					alt="シェフが料理をサーブする様子"
					width="500"
					height="158"
				/>
			</picture>
		</Outer>
	);
};
export default ChefFirstview;
