import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { Break, Attention, DefOuter } from '../style';

const Outer = styled.div`
	${DefOuter}
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;
const GoogleMap = styled.div`
	position: relative;
	width: 100%;
	max-width: 1262px;
	height: 300px;
	@media ${v.media_md} {
		height: 450px;
	}
	iframe {
		width: 100%;
		height: 100%;
	}
`;
const Table = styled.table`
	border-collapse: separate;
	border-spacing: 20px 30px;
	text-transform: uppercase;
	td {
		display: block;
		&:first-child {
			font-size: 2rem;
			letter-spacing: ${v.ls_title_sm};
			font-weight: ${v.font_weight_bold};
		}
	}
	a {
		text-transform: none;
	}
	@media ${v.media_md} {
		margin-bottom: 30px;
		border-collapse: separate;
		text-transform: uppercase;
		td {
			font-size: 1.8rem;
			display: table-cell;
			&:first-child {
				letter-spacing: ${v.ls_title_sm};
				font-weight: ${v.font_weight_bold};
			}
		}
		a {
			text-transform: none;
		}
	}
`;
const SmallTxt = styled.span`
	display: block;
	font-size: 1.6rem;
`;
const StyledAttention = styled(Attention)`
	text-align: center;
`;

const FooterAccess = () => {
	return (
		<Outer>
			<Wrapper>
				<GoogleMap>
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.0818742818938!2d134.35305891553085!3d34.06741538060177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3553a7f3dfad6a53%3A0xef9b13a8755cfbdb!2sTOGO%20DELI%20KITCHEN!5e0!3m2!1sja!2sjp!4v1643087474845!5m2!1sja!2sjp"
						width="600"
						height="400"
						style={{ border: 0 }}
						allowFullScreen=""
						loading="lazy"
					></iframe>
				</GoogleMap>
				<Table>
					<tbody>
						<tr>
							<td>access</td>
							<td>
								徳島県吉野川市鴨島町上下島81-7
								<SmallTxt>JR鴨島駅から徒歩約10分(700m)</SmallTxt>
								<SmallTxt>
									<a
										href="https://goo.gl/maps/Y1aYxW5EpJUcEKMMA"
										target="_blank"
										rel="noopener noreferrer"
									>
										GoogleMapで確認する
									</a>
								</SmallTxt>
							</td>
						</tr>
						<tr>
							<td>
								SHOP OPEN
								<Break>
									<SmallTxt>（DELI販売＆ランチ営業）</SmallTxt>
								</Break>
							</td>
							<td>
								火〜金曜日 11:00 - 15:00
								<SmallTxt>※15:00 - 18:30はお取り置き可能</SmallTxt>
								<SmallTxt>※売り切れ次第close</SmallTxt>
								<SmallTxt>※出張依頼がある日はお休みさせていただきます</SmallTxt>
							</td>
						</tr>
						<tr>
							<td>
								SPECIAL OPEN
								<Break>
									<SmallTxt>（特別営業）</SmallTxt>
								</Break>
							</td>
							<td>
								土曜日、日曜日
								<SmallTxt>
									※事前予約販売・マルシェ出店・店内イベント営業・
									出張シェフ・ケータリング等のご依頼のみ
								</SmallTxt>
							</td>
						</tr>
						<tr>
							<td>close</td>
							<td>月曜日</td>
						</tr>
					</tbody>
				</Table>
				<StyledAttention>
					営業時間・定休日は
					<Break>変更となる場合がございますので、</Break>
					<Break>ご来店前に店舗にご確認ください。</Break>
				</StyledAttention>
			</Wrapper>
		</Outer>
	);
};
export default FooterAccess;
