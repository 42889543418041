import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, Break, Title } from '../style';
import TakeoutImg1 from '../../img/takeout_firstview_01.jpg';
import TakeoutImg1_2x from '../../img/takeout_firstview_01@2x.jpg';
import TakeoutImg1_SP from '../../img/takeout_firstview_01-SP.jpg';
import TakeoutImg1_SP_2x from '../../img/takeout_firstview_01-SP@2x.jpg';

const Outer = styled.div`
	${DefOuter}
	margin-top: calc(50px + 30px);
	@media ${v.media_md} {
		margin-top: calc(90px + 60px);
		margin-bottom: 150px;
	}
`;
const StyledTitle = styled(Title)`
	padding: 0 10px;
	margin-bottom: 40px;
	@media ${v.media_md} {
		display: block;
		margin-bottom: 80px;
		/* 英語 */
		span:first-child {
			margin-right: 0;
		}
	}
`;
const Lead = styled.p`
	padding: 0 10px;
	margin-bottom: 40px;
	font-size: 1.8rem;
	font-weight: ${v.font_weight_bold};
	letter-spacing: ${v.ls_title_sm};
	line-height: 1.5;
	@media ${v.media_md} {
		margin-bottom: 80px;
		font-size: 3.2rem;
	}
`;
const Img = styled.img`
	width: 100%;
	height: auto;
	@media ${v.media_md} {
	}
`;
const TakeoutFirstview = () => {
	return (
		<Outer>
			<StyledTitle>
				<span>takeout & catered food</span>
				<span>テイクアウト＆仕出し料理</span>
			</StyledTitle>
			<Lead>
				ケーキを選ぶときのような<Break>ワクワクする気持ちで、</Break>
				<br />
				<Break>彩り豊かなお惣菜を</Break>
				<Break>選んでみませんか？</Break>
			</Lead>
			<picture>
				<source
					srcSet={`${TakeoutImg1} 1x, ${TakeoutImg1_2x} 2x`}
					media={`${v.media_sm}`}
				/>
				<Img
					src={TakeoutImg1_SP}
					srcSet={`${TakeoutImg1_SP}1x, ${TakeoutImg1_SP_2x} 2x`}
					alt="料理を選ぶ様子"
					width="500"
					height="158"
				/>
			</picture>
		</Outer>
	);
};
export default TakeoutFirstview;
