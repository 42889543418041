import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { scrollWithOffset } from '../scrollWithOffset';
import {
	DefOuter,
	BtnMore,
	Break,
	Title,
	SecTitle,
	OuterPadding,
} from '../style';
import TakeoutImg1 from '../../img/home_takeout_01.jpg';
import TakeoutImg1_2x from '../../img/home_takeout_01@2x.jpg';
import TakeoutImg2 from '../../img/home_takeout_02.jpg';
import TakeoutImg2_2x from '../../img/home_takeout_02@2x.jpg';

const Outer = styled.section`
	${DefOuter}
`;
const StyledTitle = styled(Title)`
	margin-bottom: 20px;
`;
const Wrapper = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 40px;
	${OuterPadding}
	@media ${v.media_md} {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 80px;
	}
`;
const Container = styled.div`
	margin-bottom: 20px;
	@media ${v.media_md} {
		margin-bottom: 0;
	}
`;
const Contents = styled.div`
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-around;
	align-items: center;
	position: relative;
	width: 100%;
	padding: 0 10px;
	margin-bottom: 40px;
	&:last-of-type {
		flex-direction: column;
	}
	@media ${v.media_sm} {
		flex-direction: row;
		&:last-of-type {
			flex-direction: row;
		}
	}
	@media ${v.media_md} {
		margin-bottom: 80px;
	}
`;
const Text = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	width: 100%;
	max-width: 460px;
	padding: 20px 15px;
	text-align: center;
	@media ${v.media_lg} {
		border: 3px solid ${v.color_txt};
		padding: 60px 0;
	}
`;
const Img = styled.img`
	width: 100%;
	height: auto;
	margin-bottom: 10px;
	@media ${v.media_sm} {
		width: 55%;
		max-width: 600px;
		margin-bottom: 0;
	}
`;
const StyledBtnMore = styled(BtnMore)`
	margin-top: 20px;
	margin-left: 0;
	@media ${v.media_md} {
		margin-top: 30px;
	}
`;
const HomeTakeout = () => {
	return (
		<Outer>
			<Wrapper>
				<Container>
					<StyledTitle>
						<span>takeout & catered food</span>
						<span>テイクアウト＆仕出し料理</span>
					</StyledTitle>
					<p>
						ケーキを選ぶときのようなワクワクする気持ちで、
						<Break>彩り豊かなお惣菜を選んでみませんか？</Break>
					</p>
				</Container>
				<StyledBtnMore
					smooth
					to="/takeout#"
					scroll={(el) => scrollWithOffset(el)}
				>
					read more
				</StyledBtnMore>
			</Wrapper>
			<Contents>
				<Text>
					<SecTitle>
						<span>テイクアウト</span>
						<span>takeout</span>
					</SecTitle>
					<p>普段の食卓を彩り豊かに。</p>
					<StyledBtnMore
						smooth
						to="/takeout#takeout"
						scroll={(el) => scrollWithOffset(el)}
					>
						read more
					</StyledBtnMore>
				</Text>
				<Img
					src={TakeoutImg1}
					srcSet={`${TakeoutImg1} 1x, ${TakeoutImg1_2x} 2x`}
					alt="ショーケースのお惣菜"
					width="600"
					height="370"
				/>
			</Contents>
			<Contents>
				<Img
					src={TakeoutImg2}
					srcSet={`${TakeoutImg2} 1x, ${TakeoutImg2_2x} 2x`}
					alt="オードブル"
					width="600"
					height="370"
				/>
				<Text>
					<SecTitle>
						<span>仕出し料理</span>
						<span>catered food</span>
					</SecTitle>
					<p>
						法要や大切な人の<Break>おもてなしにどうぞ。</Break>
					</p>
					<StyledBtnMore
						smooth
						to="/takeout#catered"
						scroll={(el) => scrollWithOffset(el)}
					>
						read more
					</StyledBtnMore>
				</Text>
			</Contents>
		</Outer>
	);
};
export default HomeTakeout;
