import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from '../scrollWithOffset';
import { DefOuter, Break, OuterPadding, SecTitle } from '../style';

const Outer = styled.div`
	${DefOuter}
`;
const Lead = styled.p`
	${OuterPadding}
	margin-top: 10px;
	margin-bottom: 30px;
	text-align: center;
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;
const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	@media ${v.media_md} {
	}
`;
const StyledLink = styled(HashLink)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 46%;
	max-width: 460px;
	height: 150px;
	padding: 15px 0;
	border: 3px solid ${v.color_main};
	background-color: ${v.color_main};
	text-align: center;
	color: ${v.color_sub};
	text-decoration: none;
	&:first-child {
		margin-right: calc(100% - 92% - 20px);
	}
	@media ${v.media_md} {
		width: 100%;
		height: auto;
		padding: 30px 0;
		&:first-child {
			margin-right: 50px;
		}
	}
	@media all and (any-hover: hover) {
		&:hover {
			background-color: ${v.color_sub};
			color: ${v.color_main};
		}
	}
`;
export const Title = styled(SecTitle)`
	span:first-child {
		font-size: 1.2rem;
		@media ${v.media_md} {
			font-size: 1.6rem;
		}
	}
	span:last-child {
		font-size: 2rem;
		@media ${v.media_md} {
			font-size: 3rem;
		}
	}
`;
export const Attention = styled.p`
	font-size: 1.2rem;
	@media ${v.media_md} {
		font-size: 1.8rem;
	}
`;
const TakeoutAbout = () => {
	return (
		<Outer>
			<Lead>
				トゴデリキッチンでは、
				<Break>お客様の目的に応じてお惣菜やお弁当を</Break>
				<Break>ご準備いたします。</Break>
			</Lead>
			<Wrapper>
				<StyledLink smooth to="#takeout" scroll={(el) => scrollWithOffset(el)}>
					<Title>
						<span>テイクアウト</span>
						<span>takeout</span>
					</Title>
					<Attention>
						普段の食卓を<Break>彩り豊かに。</Break>
					</Attention>
				</StyledLink>
				<StyledLink smooth to="#catered" scroll={(el) => scrollWithOffset(el)}>
					<Title>
						<span>仕出し料理</span>
						<span>catered food</span>
					</Title>
					<Attention>
						法要や大切な人の<Break>おもてなしにどうぞ。</Break>
					</Attention>
				</StyledLink>
			</Wrapper>
		</Outer>
	);
};
export default TakeoutAbout;
