import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, Break, OuterPadding } from '../style';
import DishImg1 from '../../img/chef_about_01.jpg';
import DishImg1_2x from '../../img/chef_about_01@2x.jpg';
import DishImg1_SP from '../../img/chef_about_01-SP.jpg';
import DishImg1_SP_2x from '../../img/chef_about_01-SP@2x.jpg';

const Outer = styled.div`
	text-align: center;
	${DefOuter}
`;
const Lead = styled.p`
	${OuterPadding}
	& + & {
		margin-top: 10px;
	}
	&:last-of-type {
		margin-bottom: 30px;
	}
	@media ${v.media_md} {
		&:last-of-type {
			margin-bottom: 50px;
		}
	}
`;
const Img = styled.img`
	width: 100%;
	max-width: 800px;
	height: auto;
	${OuterPadding}
`;
const ChefAbout = () => {
	return (
		<Outer>
			<Lead>
				徳島の食材・旬の地野菜を
				<Break>ふんだんに使ったコース料理。</Break>
			</Lead>
			<Lead>
				季節やテーマにそった
				<Break>テーブルコーディネートと、</Break>
				<Break>目の前で盛り付ける</Break>
				<Break>前菜パフォーマンスは</Break>
				<Break>見ても食べても楽しんでいただけます。</Break>
			</Lead>
			<Lead>
				基本はイタリアンやフレンチを
				<Break>ベースとしていますが、</Break>
				<Break>事前に打ち合わせをさせていただき、</Break>
				<br />
				<Break>好みやリクエストにお応えしながら作る</Break>
				<Break>その日限りの特別コースです。</Break>
			</Lead>
			<picture>
				<source
					srcSet={`${DishImg1} 1x, ${DishImg1_2x} 2x`}
					media={`${v.media_sm}`}
				/>
				<Img
					src={DishImg1_SP}
					srcSet={`${DishImg1_SP} 1x, ${DishImg1_SP_2x} 2x`}
					alt="お皿にのった料理"
					width="500"
					height="188"
				/>
			</picture>
		</Outer>
	);
};
export default ChefAbout;
