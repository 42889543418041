import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, Attention, TakeoutTitle, Break } from '../../style';
import BoxImg1 from '../../../img/takeout_box_01.jpg';
import BoxImg1_2x from '../../../img/takeout_box_01@2x.jpg';

const Outer = styled.section`
	${DefOuter}
	padding: 0 10px;
	margin: 40px 0;
	text-align: center;
	@media ${v.media_md} {
		margin: 80px 0;
	}
`;
const Wrapper = styled.div`
	margin-bottom: 20px;
	text-align: center;
	@media ${v.media_md} {
		margin-bottom: 40px;
	}
`;
const StyledAttention = styled(Attention)`
	margin-bottom: 10px;
`;
const Img = styled.img`
	width: 100%;
	max-width: 620px;
	height: auto;
`;

const TakeoutBox = () => {
	return (
		<Outer>
			<TakeoutTitle>
				<h3>
					自分で選べるオードブル<Break>盛り合わせBOX</Break>
				</h3>
			</TakeoutTitle>
			<Wrapper>
				<StyledAttention>
					お好きなオードブルを組み合わせて
					<Break>オリジナルのBOXが作れます。</Break>
					<br />
					オードブルのラインナップは日替わりです。
					<br />
					その日の気分で自分好みにカスタマイズ☆
				</StyledAttention>
				<Attention>※単品でも購入いただけます（¥480〜）</Attention>
				<Attention>※表示価格は全て税込です。</Attention>
			</Wrapper>
			<Img
				src={BoxImg1}
				srcSet={`${BoxImg1} 1x, ${BoxImg1_2x} 2x`}
				alt="選べるオードブル4種BOX"
				width="620"
				height="449"
			/>
		</Outer>
	);
};
export default TakeoutBox;
