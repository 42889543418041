import React from 'react';
// import { Component } from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import FirstviewImg1 from '../../img/home_firstview_01.jpg';
import FirstviewImg1_SP from '../../img/home_firstview_01-SP.jpg';
import FirstviewImg1_SP_2x from '../../img/home_firstview_01-SP@2x.jpg';
import FirstviewImg2 from '../../img/home_firstview_02.jpg';
import FirstviewImg2_SP from '../../img/home_firstview_02-SP.jpg';
import FirstviewImg2_SP_2x from '../../img/home_firstview_02-SP@2x.jpg';
import FirstviewImg3 from '../../img/home_firstview_03.jpg';
import FirstviewImg3_SP from '../../img/home_firstview_03-SP.jpg';
import FirstviewImg3_SP_2x from '../../img/home_firstview_03-SP@2x.jpg';

const Outer = styled.div`
	width: 100%;
	margin: 50px auto ${v.sec_mb};
	@media ${v.media_lg} {
		margin: 90px auto ${v.sec_mb_lg};
	}
`;
const Slide = styled.div`
	width: 100%;
	height: auto;
	@media ${v.media_xl} {
		height: 85vh;
	}
`;
const Img = styled.img`
	width: 100%;
	height: auto;
`;

const HomeFirstview = () => {
	const settings = {
		autoplay: true,
		autoplaySpeed: 3000,
		infinite: true,
		fade: true,
		pauseOnHover: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
	};
	return (
		<Outer>
			<Slider {...settings}>
				<Slide>
					<picture>
						<source srcSet={FirstviewImg1} media={`${v.media_sm}`} />
						<Img
							src={FirstviewImg1_SP}
							srcSet={`${FirstviewImg1_SP}1x, ${FirstviewImg1_SP_2x} 2x`}
							alt="食事がテーブルに並んでいる様子"
							width="800"
							height="1067"
						/>
					</picture>
				</Slide>
				<Slide>
					<picture>
						<source srcSet={FirstviewImg2} media={`${v.media_sm}`} />
						<Img
							src={FirstviewImg2_SP}
							srcSet={`${FirstviewImg2_SP}1x, ${FirstviewImg2_SP_2x} 2x`}
							alt="テーブルにお皿などが準備されている様子"
							width="800"
							height="1067"
						/>
					</picture>
				</Slide>
				<Slide>
					<picture>
						<source srcSet={FirstviewImg3} media={`${v.media_sm}`} />
						<Img
							src={FirstviewImg3_SP}
							srcSet={`${FirstviewImg3_SP}1x, ${FirstviewImg3_SP_2x} 2x`}
							alt="料理をサーブする様子"
							width="800"
							height="1067"
						/>
					</picture>
				</Slide>
			</Slider>
		</Outer>
	);
};
export default HomeFirstview;
