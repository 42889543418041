import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, OuterPadding } from '../../style';

import LunchImg1 from '../../../img/takeout_lunch_01.jpg';
import LunchImg1_2x from '../../../img/takeout_lunch_01@2x.jpg';
import LunchImg1_SP from '../../../img/takeout_lunch_01-SP.jpg';
import LunchImg1_SP_2x from '../../../img/takeout_lunch_01-SP@2x.jpg';

const Outer = styled.section`
	text-align: center;
	${OuterPadding}
	${DefOuter}
`;
export const Box = styled.div`
	position: relative;
	display: inline-block;
	padding: 40px 0 0;
	margin-top: 30px;
	background-color: ${v.color_sub};
	border: solid 3px ${v.color_main};
	font-size: 1.4rem;
	text-align: left;
	@media ${v.media_sm} {
		width: calc(100% - 30px);
		padding: 50px 10px 10px;
	}
	@media ${v.media_md} {
		width: auto;
		margin-top: 23px;
		font-size: 1.6rem;
	}
`;
export const BoxTitle = styled.h3`
	position: absolute;
	left: 50%;
	top: -30px;
	transform: translateX(-50%);
	z-index: 1;
	display: block;
	width: 95%;
	max-width: 330px;
	padding: 5px 0;
	background-color: ${v.color_main};
	color: ${v.color_sub};
	font-size: 2rem;
	font-weight: ${v.font_weight_bold};
	letter-spacing: ${v.ls_title_sm};
	text-align: center;
	@media ${v.media_sm} {
		left: -15px;
		top: -2.6rem;
		transform: translateX(0);
		text-align: left;
		padding: 5px 20px;
	}
	@media ${v.media_md} {
		left: calc(-2.6rem - 10px);
		top: -2.6rem;
		width: auto;
		max-width: none;
		padding: 10px 20px;
		font-size: 2.6rem;
	}
	span {
		display: block;
		font-size: 1.4rem;
		font-weight: normal;
		@media ${v.media_md} {
			display: inline-block;
			font-size: 1.8rem;
			font-weight: normal;
		}
	}
`;
const Img = styled.img`
	width: 100%;
	height: auto;
`;

const TakeoutLunch = () => {
	return (
		<Outer>
			<Box>
				<BoxTitle>
					ランチセット <span>※ランチタイム（11:30-14:00）限定</span>
				</BoxTitle>
				<picture>
					<source
						srcSet={`${LunchImg1} 1x, ${LunchImg1_2x} 2x`}
						media={`${v.media_sm}`}
					/>
					<Img
						src={LunchImg1_SP}
						srcSet={`${LunchImg1_SP} 1x, ${LunchImg1_SP_2x} 2x`}
						alt="ランチメニュー"
						width="500"
						height="792"
					/>
				</picture>
			</Box>
		</Outer>
	);
};
export default TakeoutLunch;
