import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { Title, Attention } from '../style';
import { scrollWithOffset } from '../scrollWithOffset';

const Outer = styled.footer`
	width: 100%;
	padding: 30px 10px 0;
	background-color: ${v.color_footer};
	@media ${v.media_md} {
		padding: 50px 20px 0;
	}
`;
const StyledTitle = styled(Title)`
	margin-bottom: 40px;
	@media ${v.media_md} {
		margin-bottom: 80px;
	}
`;
const Wrapper = styled.div`
	width: 100%;
	max-width: 1366px;
	margin: 0 auto;
	@media ${v.media_md} {
	}
`;
const Container = styled.div`
	width: 100%;
	margin-bottom: 20px;
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
	@media ${v.media_lg} {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
`;
const Inner = styled.div`
	@media ${v.media_lg} {
		width: calc(50% - 50px);
		max-width: 600px;
		margin-right: 50px;
	}
`;
const GoogleMap = styled.div`
	position: relative;
	width: 100%;
	height: 300px;
	margin-bottom: 20px;
	iframe {
		width: 100%;
		height: 100%;
	}
	@media ${v.media_md} {
		height: 400px;
	}
	@media ${v.media_lg} {
		width: 100%;
		margin-right: 5%;
		margin-bottom: 0;
	}
`;
const Text = styled.div`
	width: 100%;
	@media ${v.media_lg} {
		width: 50%;
	}
`;
const DTerm = styled.dt`
	text-transform: uppercase;
	letter-spacing: ${v.ls_title_sm};
	font-weight: ${v.font_weight_bold};
	font-size: 2rem;
`;
const DDesc = styled.dd`
	margin-bottom: 15px;
	text-transform: uppercase;
	font-size: 1.6rem;
	&:last-child {
		margin-bottom: 20px;
	}
	@media ${v.media_md} {
		font-size: 1.8rem;
	}
	@media ${v.media_lg} {
		margin-bottom: 10px;
		margin-left: 20px;
	}
`;
const SmallTxt = styled.span`
	font-size: 1.6rem;
`;
const CopyRights = styled.p`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	letter-spacing: ${v.ls_title_sm};
	line-height: 1;
	font-size: 1.2rem;
	@media ${v.media_md} {
		font-size: 1.4rem;
	}
	span {
		text-transform: uppercase;
	}
`;
const Privacypolicy = styled(Attention)`
	font-size: 1.4rem;
	text-align: center;
`;
const FooterAccess = () => {
	return (
		<Outer id="access">
			<Wrapper>
				<StyledTitle>
					<span>access</span>
					<span>アクセス</span>
				</StyledTitle>
				<Container>
					<Inner>
						<GoogleMap>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.0818742818938!2d134.35305891553085!3d34.06741538060177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3553a7f3dfad6a53%3A0xef9b13a8755cfbdb!2sTOGO%20DELI%20KITCHEN!5e0!3m2!1sja!2sjp!4v1643087474845!5m2!1sja!2sjp"
								width="600"
								height="400"
								style={{ border: 0 }}
								allowFullScreen=""
								loading="lazy"
							></iframe>
						</GoogleMap>
					</Inner>
					<Text>
						<dl>
							<DTerm>
								shop open<SmallTxt>（DELI販売＆ランチ営業）</SmallTxt>
							</DTerm>
							<DDesc>
								火〜金曜日 11:00 - 15:00
								<br />
								<SmallTxt>※15:00 - 18:30はお取り置き可能</SmallTxt>
								<br />
								<SmallTxt>※売り切れ次第close</SmallTxt>
								<br />
								<SmallTxt>※出張依頼がある日はお休みさせていただきます</SmallTxt>
							</DDesc>
							<DTerm>
								special open<SmallTxt>（特別営業）</SmallTxt>
							</DTerm>
							<DDesc>
								土曜日、日曜日
								<br />
								<SmallTxt>
									※事前予約販売・マルシェ出店・店内イベント営業・
									出張シェフ・ケータリング等のご依頼のみ
								</SmallTxt>
							</DDesc>
							<DTerm>close</DTerm>
							<DDesc>
								月曜日
								<br />
							</DDesc>
						</dl>
						<Attention>
							営業時間・定休日は変更となる場合がございますので、
							ご来店前に店舗にご確認ください。
						</Attention>
					</Text>
				</Container>
				<Privacypolicy>
					<HashLink
						smooth
						to="/privacypolicy#"
						scroll={(el) => scrollWithOffset(el)}
					>
						プライバシーポリシー
					</HashLink>
				</Privacypolicy>
				<CopyRights>
					<small>
						CopyRights 2022 <span>togodeli kitchen</span>
					</small>
				</CopyRights>
			</Wrapper>
		</Outer>
	);
};
export default FooterAccess;
