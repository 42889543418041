import React from 'react';
import styled from 'styled-components';
import TakeoutBox from './takeout/takeout-box';
// import TakeoutSingle from './takeout/takeout-single';
import TakeoutLunch from './takeout/takeout-lunch';
import { DefOuter, OuterPadding, Title } from '../style';

const Outer = styled.section`
	${DefOuter}
`;
const StyledTitle = styled(Title)`
	margin-bottom: 20px;
	${OuterPadding}
`;

const TakeoutTakeout = () => {
	return (
		<Outer id="takeout">
			<StyledTitle>
				<span>takeout</span>
				<span>テイクアウト</span>
			</StyledTitle>
			<TakeoutBox />
			{/* <TakeoutSingle /> */}
			<TakeoutLunch />
		</Outer>
	);
};
export default TakeoutTakeout;
