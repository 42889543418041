import React from 'react';
import styled from 'styled-components';
import CateredAssorted from './catered/catered-assorted';
import CateredBento from './catered/catered-bento';
import { DefOuter, Title, OuterPadding } from '../style';

const Outer = styled.section`
	${DefOuter}
`;
const StyledTitle = styled(Title)`
	margin-bottom: 20px;
	${OuterPadding}
`;

const TakeoutTakeout = () => {
	return (
		<Outer id="catered">
			<StyledTitle>
				<span>catered food</span>
				<span>仕出し料理</span>
			</StyledTitle>
			<CateredAssorted />
			<CateredBento />
		</Outer>
	);
};
export default TakeoutTakeout;
