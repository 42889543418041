import React from 'react';
import Svg from './svg';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;

// gnavアイコンの共通設定
const Icon = styled(Svg)`
	padding: 1px;
	@media ${v.media_md} {
	}
`;
export const IconFb = () => (
	<Icon width="15.25" height="26.5" viewBox="0 0 15.25 26.5">
		<path
			fill="currentcolor"
			d="M17,1.25h3.75A.75.75,0,0,1,21.5,2V7a.75.75,0,0,1-.75.75H17a.5.5,0,0,0-.5.5v3h4.25a.75.75,0,0,1,.728.932l-1.25,5a.75.75,0,0,1-.728.568h-3V27a.75.75,0,0,1-.75.75h-5A.75.75,0,0,1,10,27V17.75H7A.75.75,0,0,1,6.25,17V12A.75.75,0,0,1,7,11.25h3v-3A7.008,7.008,0,0,1,17,1.25Zm3,1.5H17a5.506,5.506,0,0,0-5.5,5.5V12a.75.75,0,0,1-.75.75h-3v3.5h3a.75.75,0,0,1,.75.75v9.25H15V17a.75.75,0,0,1,.75-.75h3.164l.875-3.5H15.75A.75.75,0,0,1,15,12V8.25a2,2,0,0,1,2-2h3Z"
			transform="translate(-6.25 -1.25)"
		/>
	</Icon>
);

export const IconInsta = () => (
	<Icon width="26.5" height="26.5" viewBox="0 0 26.5 26.5">
		<g transform="translate(0.75 0.75)">
			<path
				fill="currentcolor"
				d="M5-.75H20A5.757,5.757,0,0,1,25.75,5V20A5.757,5.757,0,0,1,20,25.75H5A5.757,5.757,0,0,1-.75,20V5A5.757,5.757,0,0,1,5-.75Zm15,25A4.255,4.255,0,0,0,24.25,20V5A4.255,4.255,0,0,0,20,.75H5A4.255,4.255,0,0,0,.75,5V20A4.255,4.255,0,0,0,5,24.25Z"
			/>
			<path
				fill="currentcolor"
				d="M14.2,7.207a6.95,6.95,0,0,1,1.016.075,6.911,6.911,0,0,1,2.093,13.009,6.941,6.941,0,0,1-3.105.738,6.911,6.911,0,0,1,0-13.823Zm0,12.323a5.411,5.411,0,0,0,5.351-6.2A5.411,5.411,0,0,0,15,8.765a5.411,5.411,0,0,0-4.62,9.179A5.378,5.378,0,0,0,14.206,19.529Z"
				transform="translate(-1.583 -1.589)"
			/>
			<path
				fill="currentcolor"
				d="M.022.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H.022A.75.75,0,0,1,.772,0,.75.75,0,0,1,.022.75Z"
				transform="translate(19.505 5.665)"
			/>
		</g>
	</Icon>
);

export const IconReserve = () => (
	<Icon width="25" height="25" viewBox="0 0 25 25">
		<path
			fill="currentcolor"
			d="M23.222,3.778H21.833V6.556H17.667V3.778H9.333V6.556H5.167V3.778H3.778A2.785,2.785,0,0,0,1,6.556V23.222A2.785,2.785,0,0,0,3.778,26H23.222A2.786,2.786,0,0,0,26,23.222V6.556A2.786,2.786,0,0,0,23.222,3.778Zm0,19.444H3.778V12.111H23.222ZM8.639,1H5.861V5.861H8.639Zm12.5,0H18.361V5.861h2.778Z"
			transform="translate(-1 -1)"
		/>
	</Icon>
);

export const IconTel = () => (
	<Icon width="25" height="24.928" viewBox="0 0 25 24.928">
		<path
			fill="currentcolor"
			d="M23.883,15.789a3.948,3.948,0,0,1-.841-.151A11.842,11.842,0,0,1,21.4,15.15,2.509,2.509,0,0,0,18.288,16.4l-.276.565a15.28,15.28,0,0,1-3.337-2.509,15.28,15.28,0,0,1-2.509-3.337l.527-.351A2.509,2.509,0,0,0,13.947,7.66a12.959,12.959,0,0,1-.489-1.643c-.063-.276-.113-.565-.151-.853A3.764,3.764,0,0,0,9.544,2.04H5.781A3.764,3.764,0,0,0,2.017,6.318,23.836,23.836,0,0,0,22.742,26.967h.477a3.755,3.755,0,0,0,3.764-3.776V19.427A3.764,3.764,0,0,0,23.883,15.789Zm.627,7.527a1.255,1.255,0,0,1-.427.941,1.317,1.317,0,0,1-1.029.314A21.327,21.327,0,0,1,4.6,6.029,1.367,1.367,0,0,1,4.915,5a1.255,1.255,0,0,1,.941-.427H9.619a1.255,1.255,0,0,1,1.255.991q.075.514.188,1.016a13.95,13.95,0,0,0,.577,1.944l-1.756.815a1.255,1.255,0,0,0-.615,1.668,18.178,18.178,0,0,0,8.782,8.782,1.329,1.329,0,0,0,1.668-.652l.778-1.756a17.174,17.174,0,0,0,1.982.577q.5.113,1.016.188a1.255,1.255,0,0,1,.991,1.255Z"
			transform="translate(-1.982 -2.04)"
		/>
	</Icon>
);
