import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, Break, YellowTitle, OuterPadding } from '../style';
import ConceptImg1 from '../../img/home_concept_01.jpg';
import ConceptImg1_2x from '../../img/home_concept_01@2x.jpg';

const Outer = styled.section`
	width: fit-content;
	margin: 0 auto 40px;
	${OuterPadding}
	${DefOuter}
	text-align: center;
	@media ${v.media_md} {
		text-align: left;
	}
	@media ${v.media_lg} {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		margin-bottom: 80px;
	}
`;
const Img = styled.img`
	display: none;
	@media ${v.media_lg} {
		display: block;
		width: 40%;
		max-width: 600px;
		height: auto;
	}
`;
const Lead = styled.p`
	line-height: ${v.lh_lead};
`;

const HomeConcept = () => {
	return (
		<Outer>
			<div>
				<h1>
					<YellowTitle>シェフごはんを</YellowTitle>
					<br />
					<YellowTitle>もっと近くに、もっと楽しく。</YellowTitle>
				</h1>
				<Lead>
					トゴデリキッチンはいろんなカタチで
					<Break>「楽しい食事」をお届けします。</Break>
				</Lead>
				<Lead>
					いつもの食事はテイクアウトデリで<Break>さらに彩り豊かに。</Break>
				</Lead>
				<Lead>
					大切な日にはいつもの場所にシェフを呼んで、
					<br />
					見た目も味もドキドキするライブ料理を。
				</Lead>
				<Lead>
					「いつも」を少しだけ特別に感じる
					<Break>シェフごはんをお楽しみください。</Break>
				</Lead>
			</div>
			<Img
				src={ConceptImg1}
				srcSet={`${ConceptImg1} 1x, ${ConceptImg1_2x} 2x`}
				alt="みんなで食事に手を伸ばす様子"
				width="600"
				height="420"
			/>
		</Outer>
	);
};
export default HomeConcept;
