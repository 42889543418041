import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, Break, Title, SecTitle, BrPC, OuterPadding } from '../style';

const Outer = styled.section`
	${DefOuter}
`;
const StyledTitle = styled(Title)`
	margin-bottom: 40px;
	${OuterPadding}
	@media ${v.media_md} {
		margin-bottom: 80px;
	}
`;
const Wrapper = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 40px;
	@media ${v.media_sm} {
		display: flex;
		justify-content: space-between;
		margin-bottom: 80px;
	}
`;
const Reserve = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	max-width: 610px;
	padding: 45px 30px;
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 15px;
		width: calc(100% - 30px);
		height: calc(100% - 30px);
		border-style: solid;
		border-width: 1px;
	}
	&:first-child {
		background-color: ${v.color_main};
		color: ${v.color_sub};
		&::before {
			border-color: ${v.color_base};
		}
	}
	&:last-child {
		background-color: ${v.color_acc};
		color: ${v.color_txt};
		&::before {
			border-color: ${v.color_txt};
		}
	}
	@media ${v.media_md} {
		width: 50%;
		height: 380px;
		padding: 75px 50px;
		&::before {
			top: 25px;
			width: calc(100% - 50px);
			height: calc(100% - 50px);
		}
	}
	a {
		color: ${v.color_acc};
	}
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	@media ${v.media_md} {
		margin-bottom: 0;
	}
`;
const Tel = styled.a`
	margin-top: 20px;
	font-weight: ${v.font_weight_bold};
	line-height: 1;
	text-align: center;
	font-size: 3rem;
	letter-spacing: ${v.ls_title_sm};
	color: ${v.color_sub};
	color: inherit !important;
	@media ${v.media_md} {
		margin-top: 30px;
		letter-spacing: ${v.ls_title_lg};
		font-size: 3.5rem;
	}
`;

const FooterReserve = () => {
	return (
		<Outer id="reserve">
			<StyledTitle>
				<span>reserve</span>
				<span>ご予約</span>
			</StyledTitle>
			<Wrapper>
				<Reserve>
					<Container>
						<SecTitle>
							<span>出張シェフ</span>
							<span>private chef</span>
						</SecTitle>
						<p>
							ご予約は下記の電話番号にご連絡または
							<BrPC />
							<a
								target="_blank"
								rel="noreferrer"
								href="https://coubic.com/togodeli_kitchen/992605#pageContent"
							>
								予約サイト
							</a>
							よりお願いいたします。
						</p>
						<Tel href="tel:0883-38-9301">0883-38-9301</Tel>
					</Container>
				</Reserve>
				<Reserve>
					<Container>
						<SecTitle>
							<span>テイクアウト&仕出し料理</span>
							<span>
								takeout & <Break>catered food</Break>
							</span>
						</SecTitle>
						<p>
							店舗受け取りの取り置き、仕出し料理のご予約は
							<BrPC />
							下記の電話番号にご連絡ください。
						</p>
						<Tel href="tel:0883-38-9301">0883-38-9301</Tel>
					</Container>
				</Reserve>
			</Wrapper>
		</Outer>
	);
};
export default FooterReserve;
