import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, Title, Btns, BtnSNS, OuterPadding } from '../style';
import { IconFb, IconInsta } from '../icon';


const Outer = styled.div`
	${DefOuter}
`;
const StyledTitle = styled(Title)`
	margin-bottom: 40px;
	${OuterPadding}
	@media ${v.media_md} {
		margin-bottom: 80px;
	}
`;
// API出力部分 START
const GalleryList = styled.ul`
	display: flex;
	flex-wrap: wrap;
`;
const GalleryItem = styled.li`
	position: relative;
	min-width: 50%;
	width: 50%;
	@media ${v.media_sm} {
		min-width: 25%;
		width: 25%;
	}
	&:before {
		content: '';
		display: block;
		padding-top: 100%;
	}
	@media all and (any-hover: hover) {
		&:hover {
			opacity: 0.7;
		}
	}
`;
const GalleryImg = styled.img`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	object-fit: cover;
`;
const GalleryVideo = styled.video`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	object-fit: cover;
`;
// API出力部分 END
const Link = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 40px;
	@media ${v.media_md} {
	}
`;

const FooterInsta = () => {
	const [posted, setPosted] = useState([]);

	useEffect(() => {
		const user_name = 'togo_deli_kitchen'; //ビジネスorクリエイターアカウントの必要あり
		const access_token =
			'EAFdLWCgZArnUBOZCZA673WpYdMPsuv4ECY1CvfXNtfdnEn8NDOCSvsKQICk14SWAUQ7oQMV394fZC8ZACwZAWpDyB4FoUAm6ACG00MRVTA007pJB5c5G81Ukegup0M3y4fYx8MqDIl9qWztfXo4rDhaST5EjGIougOnGZC1S8v8MZBS0rnZAMlVS1ykMMNqKfBuoZD';
		const user_id = '17841440298896669';
		const get_count = 8; //取得したい投稿数
		axios
			.get(
				`https://graph.facebook.com/v14.0/${user_id}?fields=business_discovery.username(${user_name}){media_count,media.limit(${get_count}){caption,timestamp,media_url,media_type,permalink,children{id,media_url,media_type}}}&access_token=${access_token}`,
			)
			//通信成功時
			.then((response) => {
				// setPosted(response.data);
				// alert(posted);
				setPosted(response.data.business_discovery.media.data);
			})
			.catch((err) => console.log(err.response));
	}, []);

	// 取得したAPIのチェック ※.then内では確認できないため
	// useEffect(() => {
	// console.log(posted);
	// }, [posted]);

	return (
		<Outer id="news">
			<StyledTitle>
				<span>news</span>
				<span>おしらせ</span>
			</StyledTitle>
			<GalleryList>
				{posted.map((insta) => (
					// media_urlに videoが含まれているとき: videoタグ / 含まれていないとき: imgタグで出力
					// thumbnail_urlはFB側のエラーで出力を吐かないためvideoで出力させる
					<GalleryItem key={insta.id}>
						{/* {console.log(insta)} */}
						<a href={insta.permalink} target="_blank" rel="noopener noreferrer">
							{insta.media_type == 'VIDEO' ? (
								<GalleryVideo
									autoPlay
									playsInline
									muted
									src={insta.media_url}
								></GalleryVideo>
							) : (
								<GalleryImg src={insta.media_url} alt="インスタ画像" />
							)}
						</a>
					</GalleryItem>
				))}
			</GalleryList>
			<Link>
				<p>最新情報はSNSをご確認ください。</p>
				<Btns>
					<BtnSNS
						target="_blank"
						rel="noreferrer"
						href="https://www.facebook.com/TOGO.DELI.KITCHEN/"
					>
						<IconFb />
						Facebook
					</BtnSNS>
					<BtnSNS
						target="_blank"
						rel="noreferrer"
						href="https://www.instagram.com/togo_deli_kitchen/"
					>
						<IconInsta />
						Instagram
					</BtnSNS>
				</Btns>
			</Link>
		</Outer>
	);
};

export default FooterInsta;
