import React from 'react';
// import ScrollRight from '../../components/scroll-right';
import FooterReserve from './footer-reserve';
import FooterInsta from './footer-insta';
import FooterAccess from './footer-access';

const Footer = () => {
	return (
		<React.StrictMode>
			<FooterReserve />
			<FooterInsta />
			<FooterAccess />
		</React.StrictMode>
	);
};
export default Footer;
