import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, SubTitle, OuterPadding, SecTitle } from '../style';
import ProfileImg1 from '../../img/chef_profile_01.jpg';
import ProfileImg1_2x from '../../img/chef_profile_01@2x.jpg';

const Outer = styled.section`
	${OuterPadding}
	${DefOuter}
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	/* max-width: 10 00px; */
	margin: 0 auto;
	@media ${v.media_sm} {
		flex-direction: row;
	}
`;
const Img = styled.img`
	width: 80%;
	height: auto;
	margin-bottom: 10px;
	@media ${v.media_sm} {
		max-width: 240px;
		margin-right: 20px;
		margin-bottom: 0;
	}
	@media ${v.media_md} {
		max-width: 300px;
		margin-right: 50px;
	}
`;
const Container = styled.div`
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
	text-align: center;
	max-width: 300px;
	margin-top: 10px;
	@media ${v.media_sm} {
		align-items: flex-start;
		max-width: none;
		text-align: left;
		/* margin-bottom: 40px;
		p + p{
			margin-top: 10px;
		} */
	}
`;
const Inner = styled.div`
	max-width: 320px;
	margin-top: 10px;
	font-size: 1.4rem;
	p + p {
		margin-top: 5px;
	}
	@media ${v.media_sm} {
		text-align: left;
	}
	@media ${v.media_md} {
		font-size: 1.6rem;
	}
`;

const ChefProfile = () => {
	return (
		<Outer>
			<SubTitle>
				<span>シェフプロフィール</span>
				<span>profile</span>
			</SubTitle>
			<Wrapper>
				<Img
					src={ProfileImg1}
					srcSet={`${ProfileImg1} 1x, ${ProfileImg1_2x} 2x`}
					alt="シェフの写真"
					width="300"
					height="380"
				/>
				<Container>
					<Inner>
						<p>
							地元野菜と旬の食材をたっぷり使った
							<br />
							コース料理をご提供いたします。
						</p>
						<p>
							お客様のお好みに合わせてお作りするので、
							<br />
							その日だけの特別なお料理になります。
						</p>
						<p>ご要望・ご希望お気軽にどうぞ!</p>
					</Inner>
					<SecTitle>
						<span>hamanishi hidenori</span>
						<span>濱西 秀範</span>
					</SecTitle>
				</Container>
			</Wrapper>
		</Outer>
	);
};
export default ChefProfile;
