import React from 'react';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;
import { OuterPadding, DefOuter, Title } from '../components/style';
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from '../components/scrollWithOffset';

const Outer = styled.section`
	${DefOuter}
	margin-top: calc(50px + 30px);
	font-size: 1.4rem;
	@media ${v.media_md} {
		margin-top: calc(90px + 60px);
		margin-bottom: 150px;
		font-size: 1.6rem;
	}
`;
const StyledTitle = styled(Title)`
	padding: 0 10px;
	margin-bottom: 40px;
	@media ${v.media_md} {
		display: block;
		margin-bottom: 80px;
		/* 英語 */
		span:first-child {
			margin-right: 0;
		}
	}
`;
const Wrapper = styled.div`
	${OuterPadding}
	max-width: 1000px;
	margin: 0 auto;
	text-align: left;
`;
const StyledSection = styled.section`
	margin-top: 30px;
	@media ${v.media_md} {
		margin-top: 30px;
	}
	p {
		margin-bottom: 5px;
		@media ${v.media_md} {
			font-size: 2rem;
		}
	}
`;

const Notfound = () => {
	return (
		<Outer>
			<StyledTitle>
				<span>NOT FOUND</span>
				<span>ページが見つかりません</span>
			</StyledTitle>
			<Wrapper>
				<StyledSection>
					<p>お探しのページは見つかりませんでした。</p>
					<HashLink smooth to="/#" scroll={(el) => scrollWithOffset(el)}>
						トップページに戻る
					</HashLink>
				</StyledSection>
			</Wrapper>
		</Outer>
	);
};
export default Notfound;
