import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// モジュール
import Gnav from './components/gnav/gnav';
import Home from './pages/home.jsx';
import Chef from './pages/chef.jsx';
import Takeout from './pages/takeout.jsx';
import Access from './pages/access.jsx';
import Privacypolicy from './pages/privacypolicy.jsx';
import Footer from './components/footer/footer';
import Notfound from './pages/notfound';

// データ
import styleVariables from './style-variables.json';

// スタイル
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
const v = styleVariables.var;

const GlobalStyle = createGlobalStyle`
  ${reset}
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html{
    width: 100%;
    background-color: ${v.color_base};
    font-family: ${v.font_fam};
    font-size: 62.5%;
    scroll-behavior: smooth;
  }
  body{
    font-size: 1.6rem;
    line-height: ${v.lh_txt};
    letter-spacing: 0;
    font-weight: ${v.font_weight}; 
    color: ${v.color_txt};
    @media ${v.media_md} {
      font-size: 2rem;
    }
  }
  /* windowsの小さい文字がジャぎる問題解消用 */
  p, h1, h2, h3, a{
    transform: rotate(0.05deg);
  }
  a{
		text-decoration: underline;
    color: ${v.color_txt};
		@media all and (any-hover: hover) {
			&:hover {
				text-decoration: none;
			}
		}
  }
  /* img下にできるmargin解消 */
  img{
    vertical-align:top;
  }
`;

ReactDOM.render(
	<React.StrictMode>
		<GlobalStyle />
		<BrowserRouter>
			<Gnav />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/chef" element={<Chef />} />
				<Route path="/takeout" element={<Takeout />} />
				<Route path="/access" element={<Access />} />
				<Route path="/privacypolicy" element={<Privacypolicy />} />
				<Route path="*" element={<Notfound />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
