import React from 'react';
import {
	ItemList,
	Item,
	ItemImg,
	ItemTitle,
	ItemAttention,
	Break,
} from '../style';
import PointImg1 from '../../img/home_point_01.jpg';
import PointImg1_2x from '../../img/home_point_01@2x.jpg';
import PointImg2 from '../../img/home_point_02.jpg';
import PointImg2_2x from '../../img/home_point_02@2x.jpg';
import PointImg3 from '../../img/home_point_03.jpg';
import PointImg3_2x from '../../img/home_point_03@2x.jpg';

const HomeConcept = () => {
	return (
		<ItemList>
			<Item>
				<ItemImg
					src={PointImg1}
					srcSet={`${PointImg1} 1x, ${PointImg1_2x} 2x`}
					alt="食材を持っている様子"
					width="300"
					height="300"
				/>
				<ItemTitle>
					地元の食材を<Break>ふんだんに</Break>
				</ItemTitle>
				<ItemAttention>
					食材にはできるだけ地元の食材を使うよう心がけ、素材の旨味を最大限高める調理を行います。生産者さんとのつながりも大事にしております。
				</ItemAttention>
			</Item>
			<Item>
				<ItemImg
					src={PointImg2}
					srcSet={`${PointImg2} 1x, ${PointImg2_2x} 2x`}
					alt="料理を盛り付けている様子"
					width="300"
					height="300"
				/>
				<ItemTitle>
					季節限定の<Break>味わい</Break>
				</ItemTitle>
				<ItemAttention>
					季節に応じて提供するメニューを変更しております。旬の食材をふんだんに使用したその時しか味わえない料理をご賞味ください。
				</ItemAttention>
			</Item>
			<Item>
				<ItemImg
					src={PointImg3}
					srcSet={`${PointImg3} 1x, ${PointImg3_2x} 2x`}
					alt="調味料をかけている様子"
					width="300"
					height="300"
				/>
				<ItemTitle>
					調味料にも<Break>こだわりを</Break>
				</ItemTitle>
				<ItemAttention>
					食材だけではなく、調理に使用する調味料も素材の味を生かすようにこだわり抜いております。
				</ItemAttention>
			</Item>
		</ItemList>
	);
};
export default HomeConcept;
