import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, YellowTitle } from '../style';
import GalleryImg1 from '../../img/chef_gallery_01.jpg';
import GalleryImg1_2x from '../../img/chef_gallery_01@2x.jpg';
import GalleryImg1_SP from '../../img/chef_gallery_01-SP.jpg';
import GalleryImg1_SP_2x from '../../img/chef_gallery_01-SP@2x.jpg';

const Outer = styled.section`
	${DefOuter}
`;
const Title = styled(YellowTitle)`
	font-size: 1.3rem;
	@media ${v.media_sm} {
		font-size: 1.8rem;
	}
	@media ${v.media_md} {
		font-size: 2.6rem;
	}
`;
const Img = styled.img`
	width: 100%;
	height: auto;
`;

const ChefGallery = () => {
	return (
		<Outer>
			<h2>
				<Title>特別な日に大切な人達とお祝いしたり、</Title>
				<br />
				<Title>忘年会や仲間内で集まってのパーティーまで、</Title>
				<br />
				<Title>お客様のご希望に合わせた楽しい時間を創ります！</Title>
			</h2>
			<picture>
				<source
					srcSet={`${GalleryImg1} 1x, ${GalleryImg1_2x} 2x`}
					media={`${v.media_sm}`}
				/>
				<Img
					src={GalleryImg1_SP}
					srcSet={`${GalleryImg1_SP}1x, ${GalleryImg1_SP_2x} 2x`}
					alt="出張シェフの様子"
					width="500"
					height="234"
				/>
			</picture>
		</Outer>
	);
};
export default ChefGallery;
