import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, Title } from '../style';

const Outer = styled.div`
	${DefOuter}
	margin-top: calc(50px + 30px);
	margin-bottom: 40px;
	@media ${v.media_md} {
		margin-top: calc(90px + 60px);
		margin-bottom: 80px;
	}
`;
const StyledTitle = styled(Title)`
	padding: 0 10px;
	@media ${v.media_md} {
		display: block;
		/* 英語 */
		span:first-child {
			margin-right: 0;
		}
	}
`;
const ChefFirstview = () => {
	return (
		<Outer>
			<StyledTitle>
				<span>access</span>
				<span>アクセス</span>
			</StyledTitle>
		</Outer>
	);
};
export default ChefFirstview;
