import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { IconReserve, IconTel } from '../icon';
import {
	DefOuter,
	SubTitle,
	OuterPadding,
	TitleBase,
	Btns,
	BtnSNS,
} from '../style';

const Outer = styled.section`
	${DefOuter}
	${OuterPadding}
`;
const Wrapper = styled.div`
	&:not(:last-child) {
		margin-bottom: 30px;
	}
	@media ${v.media_md} {
		width: 730px;
		margin: 0 auto;
		&:not(:last-child) {
			margin-bottom: 50px;
		}
	}
`;
const Title = styled.h3`
	display: flex;
	align-items: center;
	font-size: 1.8rem;
	${TitleBase}
	margin-bottom: 10px;
	@media ${v.media_md} {
		font-size: 2.2rem;
		letter-spacing: ${v.ls_title_sm};
	}
`;
const Number = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	/* 数字の位置調節用パディング */
	padding: 0 0 3px 0;
	margin-right: 10px;
	background-color: ${v.color_acc};
	border: 2px solid ${v.color_txt};
	border-radius: 50%;
	@media ${v.media_md} {
		width: 40px;
		height: 40px;
		/* 数字の位置調節用パディング */
		padding: 0 0 3px 3px;
		border-width: 3px;
		font-size: 2.4rem;
	}
`;
const Text = styled.div`
	margin-left: 40px;
	@media ${v.media_md} {
		margin-left: 50px;
	}
`;
const Btn = styled(BtnSNS)`
	&:last-child {
		font-size: 1.8rem;
	}
	@media ${v.media_md} {
		&:last-child {
			font-size: 2rem;
		}
	}
`;
const ChefPrice = () => {
	return (
		<Outer>
			<SubTitle>
				<span>ご依頼の流れ</span>
				<span>flow</span>
			</SubTitle>
			<Wrapper>
				<Title>
					<Number>1</Number>出張日当日の予約
				</Title>
				<Text>
					<p>予約サイトまたはお電話にてお願いします。</p>
					<p>ご依頼は出張日当日の2週間前までにお願いします。</p>
				</Text>
				<Btns>
					<Btn
						target="_blank"
						rel="noreferrer"
						href="https://coubic.com/togodeli_kitchen/992605#pageContent"
					>
						<IconReserve />
						予約サイト
					</Btn>
					<Btn href="tel:0883-38-9301">
						<IconTel />
						0883-38-9301
					</Btn>
				</Btns>
			</Wrapper>
			<Wrapper>
				<Title>
					<Number>2</Number>事前お打ち合わせ
				</Title>
				<Text>
					<p>
						出張日までに一度開催場所にお伺いし、打ち合わせをさせていただきます。
					</p>
					<p>お料理内容などのご要望などはこのときにお願いいたします。</p>
				</Text>
			</Wrapper>
			<Wrapper>
				<Title>
					<Number>3</Number>出張日当日
				</Title>
				<Text>
					<p>当日は約一時間前に伺います。</p>
					<p>お食事は2時間半ほどを予定しております。</p>
					<p>食材、食器、調理器具などは基本的に全て持参いたします。</p>
					<p>片付け(30分ほど)が終わり次第終了となります。</p>
				</Text>
			</Wrapper>
		</Outer>
	);
};
export default ChefPrice;
