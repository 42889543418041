import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, Attention, TakeoutTitle, Break } from '../../style';
import BentoImg1 from '../../../img/catered_bento_01.jpg';
import BentoImg1_2x from '../../../img/catered_bento_01@2x.jpg';
import BentoImg1_SP from '../../../img/catered_bento_01-SP.jpg';
import BentoImg1_SP_2x from '../../../img/catered_bento_01-SP@2x.jpg';

const Outer = styled.section`
	${DefOuter}
	margin-bottom: 40px;
	@media ${v.media_md} {
		margin-bottom: 80px;
	}
`;
const Img = styled.img`
	width: 100%;
	height: auto;
`;
const Wrapper = styled.div`
	margin-bottom: 20px;
	text-align: center;
	@media ${v.media_md} {
		margin-bottom: 40px;
	}
`;
const StyledAttention = styled(Attention)`
	margin-bottom: 10px;
`;

const CateredBento = () => {
	return (
		<Outer>
			<TakeoutTitle>
				<h3>仕出し弁当</h3>
			</TakeoutTitle>
			<Wrapper>
				<StyledAttention>
					内容はおまかせで作らせていただいておりますが、
					<br />
					食べられないものやアレルギー対応は致します。
					<br />
					お気軽にご相談下さい。
				</StyledAttention>
				<StyledAttention>
					ご予約は3日前までに<Break>お電話または店頭で承ります。</Break>
				</StyledAttention>
				<Attention>※表示価格は全て税込です。</Attention>
			</Wrapper>
			<div>
				<picture>
					<source
						srcSet={`${BentoImg1} 1x, ${BentoImg1_2x} 2x`}
						media={`${v.media_md}`}
					/>
					<Img
						src={BentoImg1_SP}
						srcSet={`${BentoImg1_SP} 1x, ${BentoImg1_SP_2x} 2x`}
						alt="仕出し弁当"
						width="690"
						height="2074"
					/>
				</picture>
			</div>
		</Outer>
	);
};
export default CateredBento;
