import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { DefOuter, BtnMore, Break, Title, OuterPadding } from '../style';
import { scrollWithOffset } from '../scrollWithOffset';
import ChefImg1 from '../../img/home_chef_01.jpg';
import ChefImg1_2x from '../../img/home_chef_01@2x.jpg';
import ChefImg1_SP from '../../img/home_chef_01-SP.jpg';
import ChefImg1_SP_2x from '../../img/home_chef_01-SP@2x.jpg';

const Outer = styled.section`
	${DefOuter}
`;
const StyledTitle = styled(Title)`
	margin-bottom: 20px;
`;
const Wrapper = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 40px;
	${OuterPadding}
	@media ${v.media_md} {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 80px;
	}
`;
const Container = styled.div`
	margin-bottom: 20px;
	@media ${v.media_md} {
		margin-bottom: 0;
	}
`;
const Img = styled.img`
	width: 100%;
	height: auto;
	@media ${v.media_md} {
	}
`;
const HomeTakeout = () => {
	return (
		<Outer>
			<Wrapper>
				<Container>
					<StyledTitle>
						<span>private chef</span>
						<span>出張シェフ</span>
					</StyledTitle>
					<p>
						特別な日に大切な人と過ごす時間を
						<Break>一緒に創造したい！</Break>
						<Break>食卓に笑顔と感動をお届けします。</Break>
					</p>
				</Container>
				<BtnMore smooth to="/chef#" scroll={(el) => scrollWithOffset(el)}>
					read more
				</BtnMore>
			</Wrapper>
			<picture>
				<source
					srcSet={`${ChefImg1} 1x, ${ChefImg1_2x} 2x`}
					media={`${v.media_sm}`}
				/>
				<Img
					src={ChefImg1_SP}
					srcSet={`${ChefImg1_SP}1x, ${ChefImg1_SP_2x} 2x`}
					alt="シェフが料理をサーブする様子"
					width="500"
					height="158"
				/>
			</picture>
		</Outer>
	);
};
export default HomeTakeout;
