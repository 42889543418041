import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import TakeoutFirstview from '../components/takeout/takeout-firstview';
import TakeoutAbout from '../components/takeout/takeout-about';
import TakeoutTakeout from '../components/takeout/takeout-takeout';
import TakeoutCatered from '../components/takeout/takeout-catered';

const Takeout = () => {
	smoothscroll.polyfill();
	return (
		<main>
			<TakeoutFirstview />
			<TakeoutAbout />
			<TakeoutTakeout />
			<TakeoutCatered />
		</main>
	);
};
export default Takeout;
